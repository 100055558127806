<template>
    <Layout />
</template>

<script>
    import Layout from './layout/views/Layout.vue';

    export default {
        name: 'App',
        components: { Layout }
    };
</script>
