export default {
    state: {
        vote: {
            id: null,
            userId: null,
            campaignId: null,
            emotionId: null,
            emotionName: null,
            emotionPreposition: null,
            iconFile: null,
            company: {
                name: null
            },
            group: {
                name: null
            },
            actor: {
                id: null,
                slug: null
            },
            reason: {
                id: null
            }
        }
    },
    getters: {
        getDataVote(state) {
            return state.vote;
        }
    },
    mutations: {
        setId(state, payload) {
            state.vote.id = payload;
        },
        setCampaignId(state, payload) {
            state.vote.campaignId = payload;
        },
        setEmotionId(state, payload) {
            state.vote.emotionId = payload;
        },
        setEmotionName(state, payload) {
            state.vote.emotionName = payload;
        },
        setEmotionPreposition(state, payload) {
            state.vote.emotionPreposition = payload;
        },
        setIconFile(state, payload) {
            state.vote.iconFile = payload;
        },
        setUserId(state, payload) {
            state.vote.userId = payload;
        },
        setCompanyName(state, payload) {
            state.vote.company.name = payload;
        },
        setGroupName(state, payload) {
            state.vote.group.name = payload;
        },
        setActorId(state, payload) {
            state.vote.actor.id = payload;
        },
        setActorName(state, payload) {
            state.vote.actor.slug = payload;
        },
        setReasonId(state, payload) {
            state.vote.reason.id = payload;
        }
    },
    actions: {
        setId(context, payload) {
            context.commit('setId', payload);
        },
        setCampaignId(context, payload) {
            context.commit('setCampaignId', payload);
        },
        setEmotionId(context, payload) {
            context.commit('setEmotionId', payload);
        },
        setEmotionName(context, payload) {
            context.commit('setEmotionName', payload);
        },
        setEmotionPreposition(context, payload) {
            context.commit('setEmotionPreposition', payload);
        },
        setIconFile(context, payload) {
            context.commit('setIconFile', payload);
        },
        setUserId(context, payload) {
            context.commit('setUserId', payload);
        },
        setCompanyName(context, payload) {
            context.commit('setCompanyName', payload);
        },
        setGroupName(context, payload) {
            context.commit('setGroupName', payload);
        },
        setActorId(context, payload) {
            context.commit('setActorId', payload);
        },
        setActorName(context, payload) {
            context.commit('setActorName', payload);
        },
        setReasonId(context, payload) {
            context.commit('setReasonId', payload);
        }
    }
};
