export default {
    info: {
        admin: {
            icon: 'mdi-account-reactivate',
            title: 'Administrador'
        },
        user: {
            icon: 'mdi-account-circle-outline',
            title: 'Aluno'
        },
        manager: {
            icon: 'mdi-account-circle-outline',
            title: 'Profissional'
        },
        company: {
            icon: 'mdi-school',
            title: 'Escola'
        }
    },
    menus: {
        admin: [
            {
                title: 'Home',
                icon: 'mdi-view-dashboard',
                path: 'dash_admin'
            },
            {
                title: 'Escolas',
                icon: 'mdi-flag',
                path: 'companies_admin'
            },
            {
                title: 'Emotions',
                icon: 'mdi-emoticon-neutral-outline',
                path: 'emotions_admin'
            },
            {
                title: 'Atores',
                icon: 'mdi-human-handsdown',
                path: 'actors_admin'
            },
            {
                title: 'Motivos',
                icon: 'mdi-fire',
                path: 'reasons_admin'
            }
        ],
        user: [
            {
                title: 'Home',
                icon: 'mdi-view-dashboard',
                path: 'dash_user'
            },
            {
                title: 'Info bullying',
                icon: 'mdi-information-outline',
                path: 'infoBullying'
            }
        ],
        manager: [
            {
                title: 'Campanhas',
                icon: 'mdi-vote-outline',
                path: 'campaigns_manager'
            },
            {
                title: 'Info bullying',
                icon: 'mdi-information-outline',
                path: 'infoBullying'
            }
        ],
        company: [
            {
                title: 'Home',
                icon: 'mdi-view-dashboard',
                path: 'dash_company'
            },
            {
                title: 'Alunos',
                icon: 'mdi-school',
                path: 'users_company'
            },
            {
                title: 'Profissionais',
                icon: 'mdi-account-tie-outline',
                path: 'managers_company'
            },
            {
                title: 'Grupos',
                icon: 'mdi-account-supervisor-circle',
                path: 'groups_company'
            },
            {
                title: 'Campanhas',
                icon: 'mdi-vote-outline',
                path: 'campaigns_company'
            },
            {
                title: 'Info bullying',
                icon: 'mdi-information-outline',
                path: 'infoBullying'
            }
        ]
    }
};
