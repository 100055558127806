<template>
    <v-container fill-height>
        <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="500">
            <v-card>
                <v-toolbar color="primary" dark> Bullying </v-toolbar>
                <v-card-text class="info-bullying">
                    <h2>{{ bullying.title }}</h2>
                    <p>{{ bullying.text }}</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-layout justify-center align-center>
            <v-flex xs12 sm6 md4 lg3 xl3>
                <v-container class="hello">{{ hello }}</v-container>
                <v-card>
                    <v-alert
                        text
                        dense
                        :color="userQueue.theme.color.pending"
                        icon="pending"
                        border="left"
                        v-if="userQueue.pending">
                        <div class="box-user">
                            <div class="message">
                                Voce tem uma solicitação de vínculo pendente!
                            </div>

                            <div class="company">
                                <div class="division"></div>
                                <div class="company-data">
                                    <div class="data-name">
                                        {{ userQueue.company.name }}
                                    </div>
                                </div>
                            </div>

                            <div class="actions">
                                <div class="accept">
                                    <v-btn
                                        title="Aceitar"
                                        color="primary"
                                        elevation="0"
                                        rounded
                                        large
                                        dense
                                        @click="actionResolveUserQueue('s')">
                                        <span>Aceitar</span>
                                    </v-btn>
                                </div>

                                <div class="reject">
                                    <v-btn
                                        title="Recusar"
                                        color="primary"
                                        elevation="0"
                                        rounded
                                        large
                                        dense
                                        @click="actionResolveUserQueue('n')">
                                        <span>Recusar</span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-alert>

                    <v-alert
                        text
                        dense
                        :color="alertCampaign.color"
                        icon="pending"
                        border="left"
                        v-if="!userQueue.pending">
                        <div v-if="campaignQueue.pending">
                            <div>Voce tem uma votação pendente!</div>

                            <v-btn
                                title="Votar"
                                color="primary"
                                elevation="0"
                                rounded
                                large
                                dense
                                @click="goTo()">
                                <span>Votar</span>
                            </v-btn>

                            <div class="box-campaignQueue">
                                <div class="division"></div>

                                <div class="company">
                                    <div class="name">
                                        {{ campaignQueue.company.name }}
                                    </div>
                                    <div class="group">
                                        {{ campaignQueue.group.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <span>Você não tem votações pendentes!</span>
                        </div>
                    </v-alert>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import messages from '@/mocks/info.mock';

    export default {
        name: 'Queue',
        data() {
            return {
                hello: null,
                dialog: false,
                bullying: {
                    id: null,
                    role: null,
                    title: null,
                    text: null
                },
                userQueue: {
                    id: null,
                    pending: false,
                    company: {
                        id: null,
                        name: null
                    },
                    theme: {
                        color: {
                            pending: 'green'
                        }
                    }
                },
                campaignQueue: {
                    pending: false,
                    company: {
                        name: null
                    },
                    group: {
                        name: null
                    }
                },
                alertUserQueue: {
                    active: true,
                    color: 'gray',
                    icon: 'pending'
                },
                alertCampaign: {
                    active: false,
                    color: 'gray',
                    icon: 'pending'
                }
            };
        },
        async mounted() {
            await this.geralLoading();
        },
        computed: {
            ...mapGetters({
                user: 'getDataUser'
            })
        },
        methods: {
            setMessageBullying() {
                const messagesFiltereds = messages.messages.filter(
                    (e) => e.role === 'ALL' || e.role === 'COMPANY'
                );

                const randomize = (min, max) => {
                    return Math.floor(Math.random() * (max - min)) + min;
                };

                const totalMessages = messagesFiltereds.length;

                const index = randomize(0, totalMessages);

                this.bullying = messagesFiltereds[index];

                this.dialog = true;
            },
            goTo() {
                this.$router.push({ name: 'campaign' });
            },
            toggleCampaignStatus() {
                this.campaignQueue.pending = true;
                this.alertCampaign.color = 'green';
            },
            clearUserQueue() {
                this.userQueue = {
                    id: null,
                    pending: false,
                    company: {
                        id: null,
                        name: null
                    },
                    theme: {
                        color: {
                            pending: 'green'
                        }
                    }
                };
            },
            actionResolveUserQueue(accepted) {
                this.$store.commit('setGlobalLoading', true);

                const userQueueId = this.userQueue.id,
                    companyId = this.userQueue.company.id;

                this.$service.http['user']
                    .resolveUserQueue(userQueueId, companyId, accepted)
                    .then(async (_) => {
                        this.clearUserQueue();

                        await this.geralLoading();
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                this.$store.commit('setGlobalLoading', false);
            },
            async serviceLoadingUserQueue() {
                await this.$service.http['user']
                    .monitoreUserQueue()
                    .then((result) => {
                        const records = result.data.docs;

                        if (records.length) {
                            const fistRecord = records[0];

                            this.userQueue.pending = true;
                            this.userQueue.id = fistRecord.id;
                            this.userQueue.company.name = fistRecord.company.name;
                            this.userQueue.company.id = fistRecord.company.id;
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            async getCompanyAndGroup(campaignQueueId) {
                await this.$service.http['user']
                    .getCampaign(campaignQueueId)
                    .then((result) => {
                        const { group, user } = result.data.doc;

                        this.$store.dispatch('setCompanyName', user.name);
                        this.$store.dispatch('setGroupName', group.name);

                        this.campaignQueue.company.name = user.name;
                        this.campaignQueue.group.name = group.name;
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            async serviceLoadingCampaign() {
                await this.$service.http['user']
                    .monitoreCampaignQueue()
                    .then(async (result) => {
                        const campaigns = result.data.docs;

                        if (campaigns.length) {
                            let campaignQueueId = null;

                            for (const campaignQueue of campaigns) {
                                this.toggleCampaignStatus();

                                campaignQueueId = campaignQueue.campaignId;

                                this.$store.dispatch('setCampaignId', campaignQueueId);

                                await this.getCompanyAndGroup(campaignQueueId);

                                break;
                            }
                        }

                        this.alertCampaign.active = true;
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            helloTransform(name) {
                const nameParts = name.split(' ');

                return `Olá ${nameParts[0]}!`;
            },
            async geralLoading() {
                this.$store.commit('setGlobalLoading', true);

                await this.serviceLoadingUserQueue();

                if (!this.userQueue.pending) {
                    await this.serviceLoadingCampaign();
                }

                this.hello = this.helloTransform(this.user.name);

                this.$store.commit('setGlobalLoading', false);

                if (!this.campaignQueue.pending && !this.userQueue.pending) {
                    this.$router.push({ name: 'menuBullying' });
                }

                this.setMessageBullying();
            }
        }
    };
</script>

<style scoped>
    .info-bullying h2 {
        margin-top: 20px;
        font-size: 28px;
        line-height: 1.3em;
        color: #000;
    }

    .info-bullying p {
        margin-top: 20px;
        font-size: 18px;
        color: #000;
        font-family: arial;
    }

    .hello {
        font-size: 30px;
    }

    /* USER */

    .box-user .message {
        font-size: 14px;
        font-weight: bold;
        color: green;
    }

    .box-user .company {
        margin-top: 10px;
    }

    .box-user .company .division {
        width: 100px;
        height: 1px;
        background-color: #ccc;
    }

    .box-user .company .company-data {
        margin-top: 5px;
    }

    .box-user .company .company-data .data-name {
        color: #000;
    }

    .box-user .actions {
        display: flex;
        margin-top: 15px;
    }

    .box-user .actions .accept {
        margin-right: 10px;
    }

    /* CAMPAIGN */

    .box-campaignQueue {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .box-campaignQueue .division {
        width: 100px;
        height: 1px;
        /* background-color: #ccc; */
    }

    .box-campaignQueue .company {
        margin-top: 15px;
    }

    .box-campaignQueue .company .name {
        color: #7b009c;
    }

    .box-campaignQueue .company .group {
        font-weight: bold;
        color: #7b009c;
    }
</style>
