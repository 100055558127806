export default {
    state: {
        emotion: {
            id: null,
            isEditionMode: false
        }
    },
    getters: {
        getDataEmotion(state) {
            return state.emotion;
        }
    },
    mutations: {
        setDataEmotion(state, payload) {
            state.emotion = payload;
        },
        setEmotionId(state, payload) {
            state.emotion.id = payload;
        },
        setEmotionEditionMode(state, payload) {
            state.emotion.isEditionMode = payload;
        }
    },
    actions: {
        setDataEmotion(context, payload) {
            context.commit('setDataEmotion', payload);
        },
        setEmotionId(context, payload) {
            context.commit('setEmotionId', payload);
        },
        setEmotionEditionMode(context, payload) {
            context.commit('setEmotionEditionMode', payload);
        }
    }
};
