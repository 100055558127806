const Signin = () => import('../views/Signin.vue');
const Signup = () => import('../views/Signup.vue');
const ActiveProfile = () => import('../views/ActiveProfile.vue');
const ForgotPassword = () => import('../views/ForgotPassword.vue');
const ChangeProfile = () => import('../views/ChangeProfile.vue');
const ChangePassword = () => import('../views/ChangePassword.vue');

export default [
    { name: 'signin', path: '/signin', component: Signin },
    { name: 'signup', path: '/signup', component: Signup },
    { name: 'activeProfile', path: '/active_profile', component: ActiveProfile },
    { name: 'forgotPassword', path: '/forgot_password', component: ForgotPassword },
    {
        name: 'changeProfile',
        path: '/change_profile',
        component: ChangeProfile,
        meta: { authorized: ['USER', 'ADMIN', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'changePassword',
        path: '/change_password',
        component: ChangePassword,
        meta: { authorized: ['USER', 'ADMIN', 'COMPANY', 'MANAGER'] }
    }
];
