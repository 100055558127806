const DashCompany = () => import('../views/DashCompany.vue');
const ListUser = () => import('../views/user/ListUser.vue');
const ListManager = () => import('../views/manager/ListManager.vue');
const ListGroup = () => import('../views/group/ListGroup.vue');
const ListCampaign = () => import('../views/campaign/ListCampaign.vue');
const FindUser = () => import('../views/user/FindUser.vue');
const FindManager = () => import('../views/manager/FindManager.vue');
const FormGroup = () => import('../views/group/FormGroup.vue');
const FormCampaign = () => import('../views/campaign/FormCampaign.vue');

export default [
    {
        name: 'dashCompany',
        path: '/dash_company',
        component: DashCompany,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'usersCompany',
        path: '/users_company',
        component: ListUser,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'managersCompany',
        path: '/managers_company',
        component: ListManager,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'groupsCompany',
        path: '/groups_company',
        component: ListGroup,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'campaignsCompany',
        path: '/campaigns_company',
        component: ListCampaign,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'findUsersCompany',
        path: '/find_users_company',
        component: FindUser,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'findManagersCompany',
        path: '/find_managers_company',
        component: FindManager,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'groupFormCompany',
        path: '/group_form_company',
        component: FormGroup,
        meta: { authorized: ['COMPANY'] }
    },
    {
        name: 'campaignFormCompany',
        path: '/campaign_form_company',
        component: FormCampaign,
        meta: { authorized: ['COMPANY'] }
    }
];
