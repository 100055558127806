export default {
    state: {
        reason: {
            id: null,
            isEditionMode: false
        }
    },
    getters: {
        getDataReason(state) {
            return state.reason;
        }
    },
    mutations: {
        setDataReason(state, payload) {
            state.reason = payload;
        },
        setReasonId(state, payload) {
            state.reason.id = payload;
        },
        setReasonEditionMode(state, payload) {
            state.reason.isEditionMode = payload;
        }
    },
    actions: {
        setDataReason(context, payload) {
            context.commit('setDataReason', payload);
        },
        setReasonId(context, payload) {
            context.commit('setReasonId', payload);
        },
        setReasonEditionMode(context, payload) {
            context.commit('setReasonEditionMode', payload);
        }
    }
};
