export default {
    state: {
        actor: {
            id: null,
            isEditionMode: false
        }
    },
    getters: {
        getDataActor(state) {
            return state.actor;
        }
    },
    mutations: {
        setDataActor(state, payload) {
            state.actor = payload;
        },
        setActorId(state, payload) {
            state.actor.id = payload;
        },
        setActorEditionMode(state, payload) {
            state.actor.isEditionMode = payload;
        }
    },
    actions: {
        setDataActor(context, payload) {
            context.commit('setDataActor', payload);
        },
        setActorId(context, payload) {
            context.commit('setActorId', payload);
        },
        setActorEditionMode(context, payload) {
            context.commit('setActorEditionMode', payload);
        }
    }
};
