let http = null;

const queueService = {
    monitoreUserQueue: () => {
        return http.get(`/monitoreUserQueue`);
    },
    resolveUserQueue: (userQueueId, companyId, accepted) => {
        return http.get(
            `/resolveUserQueue?userQueueId=${userQueueId}&companyId=${companyId}&accepted=${accepted}`
        );
    },
    monitoreCampaignQueue: () => {
        return http.get(`/monitoreCampaignQueue`);
    },
    getCampaign: (campaignId) => {
        return http.get(`/campaigns/${campaignId}`);
    }
};

const campaignService = {
    createVote: (data) => {
        const { campaignId, emotionId } = data;
        return http.get(`votes?campaignId=${campaignId}&emotionId=${emotionId}`);
    },
    createVoteActor: (data) => {
        const { id, actor } = data;
        return http.get(`createVoteActor?voteId=${id}&actorId=${actor.id}`);
    },
    createVoteActorMessage: (data) => {
        const { id, actorName } = data;

        const vote = {
            voteId: id,
            message: actorName
        };

        return http.post('/createVoteActorMessage', vote);
    },
    createVoteReason: (data) => {
        const { id, reason } = data;
        return http.get(`createVoteReason?voteId=${id}&reasonId=${reason.id}`);
    },
    createVoteReasonMessage: (data) => {
        const { id, reasonMessage } = data;

        const vote = {
            voteId: id,
            message: reasonMessage
        };

        return http.post('/createVoteReasonMessage', vote);
    },
    getListEmotions: () => {
        return http.get(`/emotions`);
    },
    getListActors: () => {
        return http.get(`/actors`);
    },
    getListReasons: () => {
        return http.get(`/reasons`);
    }
};

export default {
    install: (httpService) => {
        http = httpService;
    },
    ...queueService,
    ...campaignService
};
