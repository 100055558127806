const map = {
    signIn: (data) => {
        const { email, password } = data;

        return {
            email,
            password
        };
    },
    signUp: (data) => {
        const { name, email, password, role } = data;

        return {
            name,
            email,
            role,
            password
        };
    },
    activeProfile: (data) => {
        const { age, gender } = data;

        return {
            age,
            gender
        };
    },
    changeProfile: (data) => {
        const { name, email, currentPassword } = data;

        return {
            name,
            email,
            currentPassword
        };
    },
    changePassword: (data) => {
        const { currentPassword, password } = data;

        return {
            currentPassword,
            newPassword: password
        };
    }
};

export const dataAdapter = (service, data) => {
    const exists = map.hasOwnProperty(service);

    return exists ? map[service](data) : null;
};
