export default {
    state: {
        group: {
            id: null,
            isEditionMode: false
        }
    },
    getters: {
        getDataGroup(state) {
            return state.group;
        }
    },
    mutations: {
        setDataGroup(state, payload) {
            state.group = payload;
        },
        setGroupId(state, payload) {
            state.group.id = payload;
        },
        setGroupEditionMode(state, payload) {
            state.group.isEditionMode = payload;
        }
    },
    actions: {
        setDataGroup(context, payload) {
            context.commit('setDataGroup', payload);
        },
        setGroupId(context, payload) {
            context.commit('setGroupId', payload);
        },
        setGroupEditionMode(context, payload) {
            context.commit('setGroupEditionMode', payload);
        }
    }
};
