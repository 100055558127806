import { dataAdapter } from '../helpers/data.adapter.helper';

let http = null,
    dataAdapted = null;

const geral = {
    getCompanyStatistics: () => {
        return http.get(`/company/statistics`);
    }
};

const user = {
    getUsersByNameContainAndRole: (name, role) => {
        return http.get(`/findUser?name=${name}&role=${role}`);
    },
    createUserQueue: (userId) => {
        return http.get(`/createUserQueue/${userId}`);
    },
    getListUsersLinkedByRole: (userRole) => {
        return http.get(`/usersLinked?userRole=${userRole}`);
    },
    getListUsersQueued: (userRole) => {
        return http.get(`/listUsersQueue/${userRole}`);
    },
    unLinkUser: (userLinkedId) => {
        return http.get(`/unlinkUser/${userLinkedId}`);
    }
};

const manager = {};

const group = {
    createGroup: (data) => {
        return http.post('/groups', data);
    },
    updateGroup: (groupId, data) => {
        dataAdapted = dataAdapter('updateGroup', data);
        return http.put(`/groups/${groupId}`, dataAdapted);
    },
    getListGroups: (userId) => {
        return http.get(`/groups/user/${userId}`);
    },
    getGroupById: (groupId) => {
        return http.get(`/groups/${groupId}`);
    },
    toggleStatusActiveGroup: (groupId) => {
        return http.patch(`/groups/${groupId}`);
    },
    deleteGroup: (groupId) => {
        return http.delete(`/groups/${groupId}`);
    }
};

const campaign = {
    createCampaign: (data) => {
        return http.post('/campaigns', data);
    },
    updateCampaign: (campaignId, data) => {
        return http.put(`/campaigns/${campaignId}`, data);
    },
    getCampaignById: (campaignId) => {
        return http.get(`/campaigns/${campaignId}`);
    },
    getListCampaigns: (userId) => {
        return http.get(`/campaigns/user/${userId}`);
    },
    toggleStatusFinalizedCampaign: (campaignId) => {
        return http.patch(`/campaigns/${campaignId}`);
    },
    deleteCampaign: (campaignId) => {
        return http.delete(`/campaigns/${campaignId}`);
    }
};

export default {
    install: (httpService) => {
        http = httpService;
    },
    ...geral,
    ...user,
    ...manager,
    ...group,
    ...campaign
};
