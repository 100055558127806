let http = null;

const company = {
    getAdminStatistics: () => {
        return http.get(`/admin/statistics`);
    },
    filterCompanies: (query) => {
        return http.get(`/users/filter${query}`);
    },
    getGroupsByUserId: (companyId) => {
        return http.get(`/groups/${companyId}`);
    },
    getUsersLinkedByUserId: (companyId) => {
        return http.get(`/users/${companyId}`);
    },
    toggleStatusActiveUser: (companyId) => {
        return http.patch(`/users/${companyId}/toggleActive`);
    }
};

const emotions = {
    createEmotion: (emotion) => {
        return http.post('/emotions', emotion);
    },
    updateEmotion: (emotionId, emotion) => {
        return http.put(`/emotions/${emotionId}`, emotion);
    },
    getEmotion: (emotionId) => {
        return http.get(`/emotions/${emotionId}`);
    },
    filterEmotions: (query) => {
        return http.get(`/emotions/filter${query}`);
    },
    deleteEmotion: (emotionId) => {
        return http.delete(`/emotions/${emotionId}`);
    },
    toggleStatusActiveEmotion: (emotionId) => {
        return http.patch(`/emotions/${emotionId}`);
    }
};

const actors = {
    createActor: (actor) => {
        return http.post('/actors', actor);
    },
    updateActor: (actorId, actor) => {
        return http.put(`/actors/${actorId}`, actor);
    },
    getActor: (actorId) => {
        return http.get(`/actors/${actorId}`);
    },
    filterActors: (query) => {
        return http.get(`/actors/filter${query}`);
    },
    deleteActor: (actorId) => {
        return http.delete(`/actors/${actorId}`);
    },
    toggleStatusActiveActor: (actorId) => {
        return http.patch(`/actors/${actorId}`);
    }
};
const reasons = {
    createReason: (reason) => {
        return http.post('/reasons', reason);
    },
    updateReason: (reasonId, reason) => {
        return http.put(`/reasons/${reasonId}`, reason);
    },
    getReason: (reasonId) => {
        return http.get(`/reasons/${reasonId}`);
    },
    filterReasons: (query) => {
        return http.get(`/reasons/filter${query}`);
    },
    deleteReason: (reasonId) => {
        return http.delete(`/reasons/${reasonId}`);
    },
    toggleStatusActiveReason: (reasonId) => {
        return http.patch(`/reasons/${reasonId}`);
    }
};

export default {
    install: (httpService) => {
        http = httpService;
    },
    ...company,
    ...emotions,
    ...actors,
    ...reasons
};
