const Campaign = () => import('../views/Campaign.vue');

import Queue from '../views/Queue.vue';

export default [
    {
        name: 'campaign',
        path: '/campaign',
        component: Campaign,
        meta: { authorized: ['USER'] }
    },
    {
        name: 'queue',
        path: '/queue',
        component: Queue,
        meta: { authorized: ['USER'] }
    }
];
