export default {
    state: {
        campaign: {
            id: null,
            isEditionMode: false
        }
    },
    getters: {
        getDataCampaign(state) {
            return state.campaign;
        }
    },
    mutations: {
        setDataCampaign(state, payload) {
            state.campaign = payload;
        },
        setCampaignId(state, payload) {
            state.campaign.id = payload;
        },
        setCampaignEditionMode(state, payload) {
            state.campaign.isEditionMode = payload;
        }
    },
    actions: {
        setDataCampaign(context, payload) {
            context.commit('setDataCampaign', payload);
        },
        setCampaignId(context, payload) {
            context.commit('setCampaignId', payload);
        },
        setCampaignEditionMode(context, payload) {
            context.commit('setCampaignEditionMode', payload);
        }
    }
};
