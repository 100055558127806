import { dataAdapter } from '../helpers/data.adapter.helper';

let http = null,
    dataAdapted = null;

export default {
    install: (httpService) => {
        http = httpService;
    },
    signIn: (data) => {
        dataAdapted = dataAdapter('signIn', data);
        return http.post('/signin', dataAdapted);
    },
    forgotPassword: (email) => {
        return http.put('/forgotPassword', { email });
    },
    signUp: (data) => {
        dataAdapted = dataAdapter('signUp', data);
        return http.post('/signup', dataAdapted);
    },
    activeProfile: (data) => {
        dataAdapted = dataAdapter('activeProfile', data);
        return http.put('/activeProfile', dataAdapted);
    },
    changeProfile: (data) => {
        dataAdapted = dataAdapter('changeProfile', data);
        return http.put('/changeProfile', dataAdapted);
    },
    changePassword: (data) => {
        dataAdapted = dataAdapter('changePassword', data);
        return http.put('/changePassword', dataAdapted);
    },
    getUserById: (id) => {
        return http.get(`/users/${id}`);
    }
};
