const map = {
    createCampaign: (data) => {
        const { name, groupId, expiration } = data;

        return {
            name,
            group_id: groupId,
            expiration
        };
    },
    updateGroup: (data) => {
        const { id, name, users } = data;

        return {
            group_id: id,
            name,
            users
        };
    }
};

const dataAdapter = (service, data) => {
    const exists = map.hasOwnProperty(service);

    return exists ? map[service](data) : null;
};

export { dataAdapter };
