import account from '@/modules/account/services';
import user from '@/modules/user/services';
import company from '@/modules/company/services';
import manager from '@/modules/manager/services';
import admin from '@/modules/admin/services';

export function getServices(httpService) {
    account.install(httpService);
    user.install(httpService);
    company.install(httpService);
    manager.install(httpService);
    admin.install(httpService);

    return {
        account,
        user,
        company,
        manager,
        admin
    };
}
