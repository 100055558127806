import MenuBullying from '../views/Menu.vue';
const ConceptBullying = () => import('../views/Concept.vue');
const NewsBullying = () => import('../views/News.vue');
const ComplaintBullying = () => import('../views/Complaint.vue');
const LawsBullying = () => import('../views/Laws.vue');
const TipsBullying = () => import('../views/Tips.vue');
const InfoBullying = () => import('../views/Info.vue');

export default [
    {
        name: 'menuBullying',
        path: '/menuBullying',
        component: MenuBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'conceptBullying',
        path: '/conceptBullying',
        component: ConceptBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'newsBullying',
        path: '/newsBullying',
        component: NewsBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'complaintBullying',
        path: '/complaintBullying',
        component: ComplaintBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'lawsBullying',
        path: '/lawsBullying',
        component: LawsBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'tipsBullying',
        path: '/tipsBullying',
        component: TipsBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    },
    {
        name: 'infoBullying',
        path: '/infoBullying',
        component: InfoBullying,
        meta: { authorized: ['USER', 'COMPANY', 'MANAGER'] }
    }
];
