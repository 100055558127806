<template>
    <div class="geral">
        <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="500">
            <v-card>
                <v-toolbar color="primary" dark> Bullying </v-toolbar>
                <v-card-text class="info-bullying">
                    <h2>{{ bullying.title }}</h2>
                    <p>{{ bullying.text }}</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="box-a">
            <div class="item">
                <v-icon x-large @click="goTo('conceptBullying')">
                    mdi-information-outline
                </v-icon>
                <p>Conceito</p>
            </div>
            <div class="item">
                <v-icon x-large @click="goTo('newsBullying')">
                    mdi-newspaper-variant-outline
                </v-icon>
                <p>Notícias</p>
            </div>
        </div>
        <div class="box-b">
            <div class="item">
                <v-icon x-large @click="goTo('complaintBullying')"> mdi-bullhorn </v-icon>
                <p>Denúncia</p>
            </div>

            <div class="item">
                <v-icon x-large @click="goTo('lawsBullying')">mdi-scale-balance</v-icon>
                <p>Leis</p>
            </div>
        </div>
        <div class="box-c">
            <div class="item">
                <v-icon x-large @click="goTo('tipsBullying')">
                    mdi-gesture-double-tap
                </v-icon>
                <p>Dicas</p>
            </div>

            <div class="item"></div>
        </div>
    </div>
</template>

<script>
    import messages from '@/mocks/info.mock';

    export default {
        name: 'MenuBullying',
        data() {
            return {
                dialog: false,
                bullying: {
                    id: null,
                    role: null,
                    title: null,
                    text: null
                }
            };
        },
        mounted() {
            this.setMessageBullying();
        },
        methods: {
            goTo(name) {
                this.$router.push({ name });
            },
            setMessageBullying() {
                const messagesFiltereds = messages.messages.filter(
                    (e) => e.role === 'ALL' || e.role === 'COMPANY'
                );

                const randomize = (min, max) => {
                    return Math.floor(Math.random() * (max - min)) + min;
                };

                const totalMessages = messagesFiltereds.length;

                const index = randomize(0, totalMessages);

                this.bullying = messagesFiltereds[index];

                this.dialog = true;
            }
        }
    };
</script>

<style scoped>
    .geral {
        display: flex;
        flex-direction: column;
        width: 320px;
        margin: auto;
    }

    .info-bullying h2 {
        margin-top: 20px;
        font-size: 28px;
        line-height: 1.3em;
        color: #000;
    }

    .info-bullying p {
        margin-top: 20px;
        font-size: 18px;
        color: #000;
        font-family: arial;
    }

    .box-a,
    .box-b,
    .box-c {
        display: flex;
    }

    .box-a .item,
    .box-b .item,
    .box-c .item {
        width: 160px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .box-a .item p,
    .box-b .item p,
    .box-c .item p {
        color: #000;
        font-size: 14px;
        margin-top: 5px;
    }

    .box-b,
    .box-c {
        margin-top: 30px;
    }
</style>
