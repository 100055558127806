export default {
    messages: [
        {
            id: 0,
            role: 'ALL',
            title: 'Destrói futuras perspectivas de emprego',
            text: 'A velha visão da escola padrão diz que o bullying é uma “parte natural do crescimento”, algo que deixamos para trás quando nós chegamos à vida adulta e ao mundo do trabalho. Contudo, uma pesquisa sugere que não só isto é falso, mas também que sofrer bullying pode garantir que a vítima nunca sequer comece a trabalhar.'
        },
        {
            id: 1,
            role: 'ALL',
            title: 'Danifica a saúde mental',
            text: 'Quantos de vocês ainda lembram dos piores momentos de sua infância? Aquele vez em que você molhou as calças quando estava velho demais ou quando foi completamente humilhado por um professor arrogante? Agora imagine ter esse sentimento em relação a toda a sua infância. Seria destruidor, certo?'
        },
        {
            id: 2,
            role: 'ALL',
            title: 'Afeta toda a economia',
            text: 'Não são apenas aqueles que foram vítimas deste tipo de intimidação que têm de viver com as consequências dela. De acordo com pesquisas recentes, o bullying afeta a todos nós, quer estejamos envolvidos ou não. Nos EUA, a violência juvenil custa à economia US$ 158 bilhões dólares a cada ano.'
        },
        {
            id: 3,
            role: 'ALL',
            title: 'Aumenta a violência sexual',
            text: 'A maioria de nós consideraria o bullying na infância e a violência sexual na adolescência coisas completamente diferentes. Contudo, um estudo conjunto entre o Centro de Controle de Doenças e a Universidade de Illinois (ambos dos EUA) diz o contrário. De acordo com a pesquisa, há uma diversas evidências de uma relação entre violência sexual e bullying.'
        },
        {
            id: 4,
            role: 'ALL',
            title: 'Torna pessoas suscetíveis ao suicídio',
            text: 'Estudos afirmam que adolescentes que sofrem com gozações são cerca de 2,5 vezes mais propensos a tentar se matar. Entretanto, o que é menos conhecido é que este risco permanece com você ao longo da vida. Em 2007, um estudo britânico descobriu que adultos que tinham sido vítimas de bullying na escola eram duas vezes mais propensos a tentar o suicídio na vida adulta.'
        },
        {
            id: 5,
            role: 'ALL',
            title: 'Crianças recompensam os seus agressores',
            text: 'Se nós, adultos, somos impotentes para ajudar as crianças vítimas de bullying, é tentador pensar que talvez os nossos próprios filhos possam fazer a diferença. Só espere sentado: um estudo recente da Universidade da Califórnia em Los Angeles (EUA) revelou que, quanto mais praticam bullying, mais populares as crianças do ensino fundamental ficam.'
        },
        {
            id: 6,
            role: 'ALL',
            title: 'É da natureza humana',
            text: 'Toda sociedade na história da humanidade teve valentões, de uma forma ou de outra. Se você quer colocar a culpa em algo, não precisa ir além da evolução. O bullying existe em todo o reino animal e, em primatas, tem uma função muito específica. Os chimpanzés ou macacos que não conseguem obedecer a uma dinâmica de grupo podem colocar em perigo todos à sua volta – ou pelo menos tornar o grupo menos eficaz em sobreviver. Então, um pouco de bullying pode manter os primatas rebeldes na linha.'
        },

        {
            id: 7,
            role: 'ALL',
            title: 'Fale sobre o assunto',
            text: 'Não importa se você está na escola, na faculdade, no ambiente de trabalho, em algum grupo de internet ou até mesmo entre os seus amigos ou familiares, é fundamental falar sobre bullying com outras pessoas. '
        },
        {
            id: 8,
            role: 'ALL',
            title: 'Não minimize a situação',
            text: 'Se alguém resolve te contar sobre algum ataque que sofreu, não minimize a situação, principalmente se for um adolescente ou criança. Dependendo da situação, é muito comum a gente responder que "não foi nada demais". No entanto, só quem passar por uma situação vexatória ou de constrangimento sabe o quanto essa é uma questão delicada. Então ouça e tente ajudar do jeito que for possível.'
        },
        {
            id: 9,
            role: 'ALL',
            title: 'Identifique os alvos',
            text: 'Além de observar quem são as pessoas que estão praticando bullying, é muito necessário perceber quem são os alvos do ataque. É óbvio que não existe um perfil certo de pessoas que podem sofrer bullying, mas é sempre bom observar se a vítima tem algum problema de autoestima, é mais introvertido ou até mesmo se apresenta alguma condição que sirva de "motivo" para esse tipo de situação. Identifique e converse.'
        },
        {
            id: 10,
            role: 'ALL',
            title: 'Identifique quem pratica o bullying',
            text: 'E, claro, é mais do que necessário observar quem é a pessoa que está promovendo esse tipo de ataque. Na internet, infelizmente, é difícil descobrir os responsáveis. No entanto, se essa é uma situação que está presente no seu dia a dia, não deixe de conversar com o autor dos ataques. Assim como a vítima, essa pessoa também pode apresentar, por exemplo, sinais de baixa autoestima. Às vezes existe uma questão muito maior que faz com que essa criança, jovem ou adulto seja violento com outros.'
        },
        {
            id: 11,
            role: 'ALL',
            title: 'Incentive elogios',
            text: 'Não importa em onde você esteja, na escola ou no ambiente de trabalho, se tem filhos ou não, seja o tipo de pessoa que estimula coisas boas. A internet, principalmente, está cheia de haters, que não vão sumir tão cedo. Então, no lugar de reforçar esse tipo de comportamento, tente fazer o contrário. Contribua para que o mundo se torne um ambiente melhor.'
        },

        {
            id: 12,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'Cerca de 160.000 crianças americanas faltam à escola todos os dias devido ao medo de sofrer algum tipo de agressão, seja física ou verbal.'
        },
        {
            id: 13,
            role: 'ALL',
            title: 'Você sabia?',
            text: '75% dos incidentes que ocorrem nas escolas de tiro/academia de tiro estão associadas ao bullying e ao assédio.'
        },
        {
            id: 14,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'O bullying afeta quase 1 em cada 3 crianças estudantes americanas, nas grades 6 a 10 anos. Equivalente ao Ensino Fundamental no Brasil.'
        },
        {
            id: 15,
            role: 'ALL',
            title: 'Você sabia?',
            text: '6 em cada 10 adolescentes nos Estados Unidos dizem testemunhar o bullying na escola pelo menos 1 vez ao dia.'
        },
        {
            id: 16,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'Em 1981, um homem que estava cometendo bullying contra moradores de uma pequena cidade no estado de Missouri, nos Estados Unidos, foi assassinado em plena luz do dia, quando 46 pessoas se levantaram contra ele em um ato de justiça e o mataram.'
        },
        {
            id: 17,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'Pessoas com as iniciais do nome relacionadas à palavras ofensivas como P.I.G, ou A.S.S, têm significativamente menor expectativa de vida, devido ao estresse psicológico relacionado à intimidação sofrida na infância.'
        },
        {
            id: 18,
            role: 'ALL',
            title: 'Você sabia?',
            text: '64% das crianças que foram alvo do bullying, não relatam a ninguém que sofreram agressão.'
        },
        {
            id: 19,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'Michael Phelps, o atleta olímpico mais condecorado de todos os tempos, sofreu bullying na infância. Devido à sua forma desengonçada e suas orelhas grandes, o atleta foi vítima de inúmeras formas de agressão quando estava na escola.'
        },
        {
            id: 20,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'Quando era criança, Elon Musk (empreendedor que tem como destaque a criação e a participação em empresas como o SolarCity, a SpaceX, e a Tesla Motors) sofreu bullying de uma maneira tão severa que, ele teve que ser hospitalizado por causa de uma surra sofrida por outros estudantes.'
        },
        {
            id: 21,
            role: 'ALL',
            title: 'Você sabia?',
            text: 'Em 2013, um pai reportou bullying por parte de um treinador em uma escola de futebol americano no estado do Texas, nos Estados Unidos. O motivo? O time do filho perdeu de 91 a 0.'
        }
    ]
};
