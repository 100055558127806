const DashManager = () => import('../views/DashManager.vue');
const ListCampaign = () => import('../views/campaign/ListCampaign.vue');
const MonitoreCampaign = () => import('../views/campaign/MonitoreCampaign.vue');

export default [
    {
        name: 'dashManager',
        path: '/dash_manager',
        component: DashManager,
        meta: { authorized: ['MANAGER'] }
    },
    {
        name: 'campaignsManager',
        path: '/campaigns_manager',
        component: ListCampaign,
        meta: { authorized: ['MANAGER'] }
    },
    {
        name: 'monitoreCampaign',
        path: '/monitore_campaign',
        component: MonitoreCampaign,
        meta: { authorized: ['MANAGER'] }
    }
];
