const DashAdmin = () => import('../views/DashAdmin.vue');
const ListCompany = () => import('../views/company/ListCompany.vue');
const ListActor = () => import('../views/actor/ListActor.vue');
const FormActor = () => import('../views/actor/FormActor.vue');
const ListEmotion = () => import('../views/emotion/ListEmotion.vue');
const FormEmotion = () => import('../views/emotion/FormEmotion.vue');
const ListReason = () => import('../views/reason/ListReason.vue');
const FormReason = () => import('../views/reason/FormReason.vue');

export default [
    {
        name: 'dashAdmin',
        path: '/dash_admin',
        component: DashAdmin,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'companiesAdmin',
        path: '/companies_admin',
        component: ListCompany,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'actorsAdmin',
        path: '/actors_admin',
        component: ListActor,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'formActorAdmin',
        path: '/form_actor_admin',
        component: FormActor,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'emotionsAdmin',
        path: '/emotions_admin',
        component: ListEmotion,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'formEmotionAdmin',
        path: '/form_emotion_admin',
        component: FormEmotion,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'reasonsAdmin',
        path: '/reasons_admin',
        component: ListReason,
        meta: { authorized: ['ADMIN'] }
    },
    {
        name: 'formReasonAdmin',
        path: '/form_reason_admin',
        component: FormReason,
        meta: { authorized: ['ADMIN'] }
    }
];
